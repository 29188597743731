import { LoginComponent } from "./login/login.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { RestorePasswordComponent } from "./restore-password/restore-password.component";
import { DriverPublicRegistrationComponent } from "./driver-public-registration/driver-public-registration.component";
import { MessageEmailComponent } from "./forgot-password/message-email.component";
import { EcocargaPoliticsComponent } from "./ecocarga_ politics/ecocarga- politics.component";
import { PersonalDataComponent } from "./personal_data/personal_data.component";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: "", component: LoginComponent },
        { path: "login", component: LoginComponent },
        { path: "forgot-password", component: ForgotPasswordComponent },
        { path: "restore-password/:id", component: RestorePasswordComponent },
        // { path: "restore-password", component: RestorePasswordComponent },
        { path: "driver-registration", component: DriverPublicRegistrationComponent },
        { path: "message-email", component:MessageEmailComponent },
        { path: "ecocarga-politics", component:EcocargaPoliticsComponent },
        { path: "ecocarga-personaldata", component:PersonalDataComponent },

      ],
      { useHash: true }
    ),
  ],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
