import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestorePassword } from './restore-password';
import { RestorePasswordService } from './restore-password.service';
import { ConfirmationService, MessageService } from "primeng/api";

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
  providers: [RestorePasswordService, ConfirmationService,MessageService]
})

export class RestorePasswordComponent implements OnInit, OnDestroy {

  restorePassword = new RestorePassword(null, null, null)
  private sub: any;

  constructor(
    private _router: Router, 
    private _activatedRoute: ActivatedRoute, 
    private _restorePasswordService: RestorePasswordService,
    private messageService: MessageService,
    ) { }

  ngOnInit() {
    this.messageService.add({ severity: 'success', summary: 'Bien!', detail: 'Te hemos enviado mensaje a tu correo electrónico para que puedas cambiar tu contraseña.' });

    this.sub = this._activatedRoute.params.subscribe(params => {
      if(params['id']){
        this.restorePassword.token = params['id'];
      }
    });
  }

  ngOnDestroy() {
  }

  onSubmit(f: any) {
    this._restorePasswordService.restorePassword(this.restorePassword)
      .then(data => {
        this._router.navigate(['/login']);
      })
      .catch(error => {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Ocurrio un error al recuperar contraseña.' });
        // alert("Ocurrio un error al recuperar contraseña");
      });
  }

}
