import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginResponse } from "../login/login-response";

@Component({
  selector: "app-sidebar",
  templateUrl: "./app-sidebar.component.html",
  styleUrls: ["./app-sidebar.component.css"],
})
export class AppSidebarComponent implements OnInit {
  constructor(private _router: Router) { }

  ngOnInit() { }
  userLoginResponse: LoginResponse = JSON.parse(localStorage.getItem("user")) as LoginResponse;
  logout() {
    var r = confirm("¿Estas seguro que deseas cerrar sesión?");
    if (r == true) {
      localStorage.clear();
      // this._router.navigate(["./login"]);
      // window.location.replace('./login');
      // this._router.navigate(["/login"]);
      location.replace('/#/');
      location.reload();


    }
  }
}
