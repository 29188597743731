import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { DocumentType } from "./documentType";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class DocumentTypeService {
  private apiEndpoint = environment.apiUrl;
  private apiEndpointRndc = environment.apiUrlRndc;

  constructor(private http: HttpClient) { }

  getDocumentTypes(): Promise<any> {
    let url = `${this.apiEndpoint}/documentTypes`;

    return this.http
      .get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }
  public getTiposIdentificacion() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpointRndc}/1.0/Maestros/TiposIdentificacion`;

    return this.http
      .get(url, {
        headers: headers,
      });
  }

  getDocumentType(id: number): Promise<any> {
    let url = `${this.apiEndpoint}/documentTypes/${id}`;

    return this.http
      .get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  saveDocumentType(documentType: DocumentType): Promise<any> {
    if (documentType.id) {
      return this.updateDocumentType(documentType);
    }
    return this.addDocumentType(documentType);
  }

  private addDocumentType(documentType: DocumentType): Promise<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpoint}/documentTypes`;

    return this.http
      .post(url, documentType, {
        headers: headers,
      })
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  private updateDocumentType(documentType: DocumentType) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpoint}/documentTypes/${documentType.id}`;

    return this.http
      .patch(url, documentType, {
        headers: headers,
      })
      .toPromise()
      .then(() => documentType)
      .catch(this.handleError);
  }

  private handleError(error: any) {
    console.error("error", error);
    return Promise.reject(error.message || error);
  }
}
