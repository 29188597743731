import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ForgotPassword } from "./forgot-password";
import { ForgotPasswordService } from "./forgot-password.service";
import { ConfirmationService, MessageService } from "primeng/api";

@Component({
  selector: "app-message-email",
  templateUrl: "./message-email.component.html",
  styleUrls: ["./message-email.component.scss"],
  providers: [ConfirmationService,MessageService],
})
export class MessageEmailComponent implements OnInit, OnDestroy {
  forgotPassword = new ForgotPassword(null);

  constructor(
    private _router: Router,
    private messageService: MessageService,

  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

}
