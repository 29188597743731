import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { Login } from "./login";
import { environment } from "../../environments/environment";
import { LoginResponse } from "./login-response";
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoginService {
  private apiEndpoint = environment.apiUrl;
  private currentUserSubject: BehaviorSubject<LoginResponse>;


  constructor(private http: Http) {
    this.currentUserSubject = new BehaviorSubject<LoginResponse>(JSON.parse(localStorage.getItem('user')));
  }

  login(user: Login): Promise<LoginResponse> {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpoint}/auth`;

    return this.http
      .post(url, user, {
        headers: headers
      })
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    this.currentUserSubject.next(null);
}

  getCurrentUserFromLocal() {
    // remove user from local storage to log user out
    return localStorage.getItem('user');
}

public get currentUserValue(): LoginResponse {
  return this.currentUserSubject.value;
}

  private handleError(error: any) {
    console.error("error", error);
    return Promise.reject(error.message || error);
  }
}
