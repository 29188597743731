import { Inject, Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { Travel } from "./travel";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from "moment";

@Injectable()
export class TravelService {
  private apiEndpoint = environment.apiUrl;
  private _httpClient: HttpClient;
  private apiEndpointRndc = environment.apiUrlRndc;

  constructor(@Inject(HttpClient) httpClient: HttpClient, private http: Http) {
    this._httpClient = httpClient;
  }

  getTravelsNew() {
    return this._httpClient.get(`${this.apiEndpoint}/travels/status/active`);
  }
  getTravels(): Promise<any> {
    let url = `${this.apiEndpoint}/travels/status/active`;

    // return this.http
    //   .get(url)
    //   .toPromise()
    //   .then((response) => response.json())
    //   .catch(this.handleError);

    return this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }


  getTravelsWithFilters(fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/status/active?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  getTravelsComplete(): Promise<any> {
    let url = `${this.apiEndpoint}/travels/status/complete`;

    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }


  getTravelsCompleteWithFilters(fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/status/complete?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  getTravelsInactives(): Promise<any> {
    let url = `${this.apiEndpoint}/travels/status/inactive`;

    return this._httpClient.get(url).toPromise();
  }

  getTravelsInactivesWithFilters(fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/status/inactive?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }


  getTravelsCreated(): Promise<any>{
    let url = `${this.apiEndpoint}/travels/status/created`;
    return this._httpClient.get(url).toPromise();
  }  
  

  getTravelsCreatedWithFilters(fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/status/created?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }



  getVehicle(id: number): Promise<Object> {
    let url = `${this.apiEndpoint}/vehicles/driver/${id}`;

    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  getTravel(id: number): Promise<any> {
    let url = `${this.apiEndpoint}/travels/detail/${id}`;

    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  getTravelsBySupervisor(id: number) {
    let url = `${this.apiEndpoint}/travels/supervisor/${id}/travels`;

    return  this._httpClient.get(url);
  }

  getCitiesDepartment(id: number): Promise<any> {
    let url = `${this.apiEndpoint}/cities/department/${id}`;

    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  patchTravel(idTravel: number, travelBody: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    })
    const url = `${this.apiEndpoint}/travels/${idTravel}`;

    return  this._httpClient.patch(url, JSON.stringify(travelBody), { headers });
  }

  updateTravelStatus(id: number, status:string) {
    let url = `${this.apiEndpoint}/travels/changeTravekStatus/${id}/${status}`;

    return  this._httpClient.patch(url,{});
  }

  uploadTravelFile(file, filename) {
    const formData = new FormData();
    formData.append("file", file, filename);
    formData.append("filename", filename);
    const url = `${this.apiEndpoint}/UploadFiles`;

    return  this._httpClient.post(url, formData);
  }

  uploadTravelAttachmentFile(file, filename,category,travelId,TravelsAttachmentId=null) {
    const formData = new FormData();
    formData.append("file", file, filename);
    formData.append("filename", filename);
    formData.append("category", category ?? '');
    formData.append("travelId", travelId ?? '');
    if (TravelsAttachmentId) {
      formData.append("TravelsAttachmentId", TravelsAttachmentId);
    }
    const url = `${this.apiEndpoint}/Travels/uploadFilesTravel`;

    return  this._httpClient.post(url, formData);
  }

  deleteTravelAttachmentFile(TravelsAttachmentId) {
    
    const url = `${this.apiEndpoint}/Travels/TravelAttachments/${TravelsAttachmentId}`;

    return  this._httpClient.delete(url);
  }

  saveTravel(travel: Travel): Promise<Travel> {
    if (travel.id) {
      return this.updateTravel(travel);
    }
    return this.addTravel(travel);
  }

  deleteTravel(id: number) {
    let url = `${this.apiEndpoint}/travels/${id}`;

    return  this._httpClient.delete(url);
  }

  private addTravel(travel: Travel): Promise<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpoint}/travels`;

    return  this._httpClient.post(url, travel, {
        headers: headers,
      })
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  private updateTravel(travel: Travel) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpoint}/travels/${travel.id}`;

    return  this._httpClient.patch(url, travel, {
        headers: headers,
      })
      .toPromise()
      .then(() => travel)
      .catch(this.handleError);
  }

  private handleError(error: any) {
    return Promise.reject(error.message || error);
  }
  public getNaturalezasCarga() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpointRndc}/1.0/Maestros/NaturalezasCarga`;

    return this._httpClient
      .get(url, {
        headers: headers,
      });
  }


  getTravelsSupervisorCompleteWithFilters(id: number, fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/supervisor/${id}/travels/complete?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }


  getTravelsSupervisorActiveWithFilters(id: number, fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/supervisor/${id}/travels/active?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }


  getTravelsSupervisorInactiveWithFilters(id: number, fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/supervisor/${id}/travels/inactive?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }


  getTravelsSupervisorCreatedsWithFilters(id: number, fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/supervisor/${id}/travels/created?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }


  getTotalTravelsByDriverFilter(fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/get-total-travels-by-driver?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }


  patchTravelWeightScale(id: number, travelBody: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    })
    let url = `${this.apiEndpoint}/travels/changeWeightScale/${id}`;


    return  this._httpClient.patch(url, JSON.stringify(travelBody), { headers });
  }


  patchTravelAdvance(id: number, travelBody: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    })
    let url = `${this.apiEndpoint}/travels/changeAdvance/${id}`;

    return  this._httpClient.patch(url, JSON.stringify(travelBody), { headers });
  }


  patchTravelsAdvance(id: number, advance: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    })
    let url = `${this.apiEndpoint}/travels/changeAdvance/${id}/${advance}`;
    return  this._httpClient.patch(url, JSON.stringify(advance), { headers });
  }


  updateTravelStatus2(id: number, advance:string) {
    let url = `${this.apiEndpoint}/travels/changeAdvance/${id}/${advance}`;

    return  this._httpClient.patch(url,{});
  }


  getTravelsCancelleds(): Promise<any> {
    let url = `${this.apiEndpoint}/travels/status/cancelled`;

    return this._httpClient.get(url).toPromise();
  }

  getTravelsCancelledsWithFilters(fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/status/cancelled?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  getTravelsSupervisorCancelledsWithFilters(id: number, fromDate, toDate, driverId=null): Promise<any> {
    let url = `${this.apiEndpoint}/travels/supervisor/${id}/travels/cancelled?fromDate=${fromDate}&toDate=${toDate}`;
    if(driverId!=null){
      url+=`&driverId=${driverId}`
    }
    return  this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }


}
