import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Login } from "./login";
import { LoginService } from "./login.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [LoginService,MessageService],
})
export class LoginComponent implements OnInit, OnDestroy {
  login = new Login("", "");

  constructor( private messageService: MessageService,private _router: Router, private _loginService: LoginService) {}

  ngOnInit() {}

  ngOnDestroy() {}

  onSubmit(f: any) {
    this._loginService
      .login(this.login)
      .then((data) => {
        console.log(data);
        if (data.user.type == "ADMIN" || data.user.type == "SUPERVISOR"|| data.user.type == "DASHBOARDUSER"|| data.user.type == "MAINTENANCE" ) {
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.setItem("userId", data.user.id.toString());
          this._router.navigate(["/admin/dashboard"]);
        } else {
          this.messageService.add({severity:'error', summary:'Error!', detail:'Usuario no autorizado.'});
        }
      })
      .catch((error) => {
        this.messageService.add({severity:'error', summary:'Error!', detail:'Usuario y contraseña incorrectos.'});
      });
  }
}
