import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminComponent } from "./admin.component";
import { AppSidebarComponent } from "../app-sidebar/app-sidebar.component";
import { AppHeaderComponent } from "../app-header/app-header.component";
import { AppFooterComponent } from "../app-footer/app-footer.component";
import { AdminRoutingModule } from "./admin.routing";
import { DataTablesModule } from "angular-datatables";
import { NgSelectModule } from "@ng-select/ng-select";
import { AuthGuard } from "../auth-guard";
import { SnackbarModule } from "ngx-snackbar";
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';



@NgModule({
  imports: [CommonModule, AdminRoutingModule, DataTablesModule, NgSelectModule, BrowserModule,
    BrowserAnimationsModule,
    MessagesModule,
    MessageModule,
    ButtonModule,
    InputTextModule,
    RippleModule,
    FormsModule
  ],
  declarations: [
    AdminComponent,
    AppSidebarComponent,
    AppHeaderComponent,
    AppFooterComponent,
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthGuard],
})
export class AdminModule { }
