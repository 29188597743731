export const environment = {
    production: false,
    //apiUrl: "https://api-stg.ecocargapp.com",
    // apiUrl: "https://localhost:44335",
    // apiUrl: "https://localhost:44360",
    //  apiUrl: "https://localhost:5001",
    //apiUrl: "https://api.ecocargapp.com", 
    // apiUrl: "https://api.ecocargapp.com",
    // // apiUrl: "https://ui-stg.ecocargapp.com",
    // apiUrl: "https://api-dev.ecocargapp.com",
    apiUrl: "https://tapi.ecocargapp.com",//integración
    apiUrlRndc: "https://rndc-stg.ecocargapp.com",
    //apiUrl: "http://localhost:3000",
  };
