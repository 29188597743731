import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-ecocarga- politics",
  templateUrl: "./ecocarga- politics.component.html",
  styleUrls: ["./ecocarga- politics.component.scss"],
})
export class EcocargaPoliticsComponent implements OnInit, OnDestroy {

  constructor(
    private _router: Router,
  ) {}


  ngOnInit() {}

  ngOnDestroy() {}

}
