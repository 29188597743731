import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-personal_data',
  templateUrl: './personal_data.component.html',
  styleUrls: ['./personal_data.component.scss']
})
export class PersonalDataComponent implements OnInit {

  constructor(
    private _router: Router,
  ) { }

  ngOnInit(): void {
  }

}
