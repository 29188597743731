import { NgModule, Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdminComponent } from "./admin.component";
import { AuthGuard } from "../auth-guard";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "admin",
        component: AdminComponent,
        children: [
          {
            path: "",
            loadChildren: "./dashboard/dashboard.module#DashboardModule",
          },
          {
            path: "dashboard",
            loadChildren: "./dashboard/dashboard.module#DashboardModule",
          },
          {
            path: "users",
            loadChildren: "./user/user.module#UserModule",
          },
          {
            path: "roles",
            loadChildren: "./role/role.module#RoleModule",
          },
          {
            path: "permissions",
            loadChildren: "./permission/permission.module#PermissionModule",
          },
          {
            path: "travels",
            loadChildren: "./travel/travel.module#TravelModule",
          },
          {
            path: "departments",
            loadChildren: "./department/department.module#DepartmentModule",
          },
          {
            path: "cities",
            loadChildren: "./city/city.module#CityModule",
          },
          {
            path: "freights",
            loadChildren: "./freight/freight.module#FreightModule",
          },
          {
            path: "incidences",
            loadChildren: "./incidence/incidence.module#IncidenceModule",
          },
          {
            path: "banks",
            loadChildren: "./bank/bank.module#BankModule",
          },
          {
            path: "documentTypes",
            loadChildren:
              "./documentType/documentType.module#DocumentTypeModule",
          },
          {
            path: "vehicleTypes",
            loadChildren: "./vehicleType/vehicleType.module#VehicleTypeModule",
          },
          {
            path: "payments",
            loadChildren: "./payment/payment.module#PaymentModule",
          },
          {
            path: "driver",
            loadChildren: "./driver/driver.module#DriverModule",
          },
          {
            path: "thirdparties",
            loadChildren: "./third/third.module#ThirdModule",
          },
          {
            path: "company",
            loadChildren: "./company/company.module#CompanyModule",
          },
          {
            path: "vehicle",
            loadChildren: "./vehicle/vehicle.module#VehicleModule",
          },
          {
            path: "genericgroup",
            loadChildren: "./genericGroup/genericGroup.module#GenericGroupModule",
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
