import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ForgotPassword } from './forgot-password';
import { environment } from '../../environments/environment';


@Injectable()
export class ForgotPasswordService {

    private apiEndpoint = environment.apiUrl;

    constructor(private http: Http) {}

    forgotPassword(user: ForgotPassword): Promise <Boolean> {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');

        let url = `${this.apiEndpoint}/auth/forgot-password`;

        return this.http.post(url, user, {
                headers: headers
            })
            .toPromise()
            .then(response => response.json())
            .catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('error', error);
        return Promise.reject(error.message || error);
    }

}
