import { Injectable, forwardRef, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LoginService } from '../../login/login.service';
export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private baseUrl: string;

    constructor(@Inject(forwardRef(() => LoginService)) private authenticationService: LoginService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
    ) {
        console.log('pasasndo por el interceptor')
        this.baseUrl = baseUrl ? baseUrl : "";
     }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        const currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.token["accessToken"];
       // const isApiUrl = request.url.startsWith(this.baseUrl);
        if (isLoggedIn ) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token["accessToken"]}`
                }
            });
        }

        return next.handle(request);
    }
}