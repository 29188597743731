import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ForgotPassword } from "./forgot-password";
import { ForgotPasswordService } from "./forgot-password.service";
import { ConfirmationService, MessageService } from "primeng/api";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
  providers: [ForgotPasswordService, ConfirmationService,MessageService],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgotPassword = new ForgotPassword(null);

  constructor(
    private _router: Router,
    private _forgotPasswordService: ForgotPasswordService,
    private messageService: MessageService,

  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  onSubmit(f: any) {
    this._forgotPasswordService
      .forgotPassword(this.forgotPassword)
      .then((data) => {
        // this.messageService.add({ severity: 'success', summary: 'Bien!', detail: 'Te hemos enviado mensaje a tu correo electrónico para que puedas cambiar tu contraseña.' });

        // alert("Te hemos enviado mensaje a tu correo electrónico para que puedas cambiar tu contraseña ");
        // this._router.navigate(["/restore-password/:id"]);
        this._router.navigate(["/message-email"]);
      })
      .catch((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Ocurrio un error al recuperar contraseña.' });

        // alert("Ocurrio un error al recuperar contraseña");
      });
  }
}
