import { Inject, Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { User } from "./user";
import { environment } from "../../../environments/environment";
import { Vehicle } from "./vehicle";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserService {
  private apiEndpoint = environment.apiUrl;
  private apiEndpointRndc = environment.apiUrlRndc;
  private _httpClient: HttpClient;

  constructor(@Inject(HttpClient) httpClient: HttpClient, private http: Http) {
    this._httpClient = httpClient;
  }

  getUsers(): Promise<any> {
    let url = `${this.apiEndpoint}/users/type/users`;

    return this._httpClient
      .get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  getDrivers() {
    let url = `${this.apiEndpoint}/users/type/drivers`;

    return this._httpClient
      .get(url);
  }


  getSupervisors() {
    let url = `${this.apiEndpoint}/users/type/supervisor`;

    return this._httpClient
      .get(url);
  }


  getDashboarduser() {
    let url = `${this.apiEndpoint}/users/type/dashboarduser`;

    return this._httpClient
      .get(url);
  }

  getAdmins(): Promise<any> {
    let url = `${this.apiEndpoint}/users/type/admins`;

    return this._httpClient
      .get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  getVehiclesTypes(): Promise<any> {
    let url = `${this.apiEndpoint}/vehicleTypes`;

    return this._httpClient
      .get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  getUser(id: number): Promise<any> {
    let url = `${this.apiEndpoint}/users/${id}`;

    return this._httpClient
      .get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  getPayments(id: number): Promise<any> {
    let url = `${this.apiEndpoint}/driverPaymentMethods/driver/${id}`;

    return this._httpClient.get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  saveUser(user: User): Promise<any> {
    if (user.id) {
      return this.updateUser(user);
    }
    return this.addUser(user);
  }


  getMaintenaceUser() {
    let url = `${this.apiEndpoint}/users/type/maintenaceUser`;
    return this._httpClient
      .get(url);
  }

  upsertingVehicleByUser(vehicle: Vehicle, soatFile: Blob, soatFileName: string, enrollmentFile: Blob, enrollmentFileName: string) {
    const formData = new FormData();
    formData.append("Id", vehicle?.id?.toString() ?? '0');
    formData.append("DriverId", vehicle.driverId.toString() ?? '0');
    formData.append("Model", vehicle.model ?? '');
    formData.append("Capacity", vehicle.capacity ?? '');
    // formData.append("TypeId", vehicle.typeId.toString());
    if (soatFile) {
      formData.append("SoatFile", soatFile, soatFileName);
    }
    if (enrollmentFile) {
      formData.append("EnrollmentFile", enrollmentFile, enrollmentFileName);
    }
    formData.append("Enrollment", vehicle.enrollment);
    formData.append("LicensePlate", vehicle.licensePlate ?? '');
    formData.append("LicensePlateUrl", vehicle.licensePlate ?? '');
    formData.append("SoatUrl", vehicle.soat ?? '');
    formData.append("SoatExpirationDate", vehicle.soatExpirationDate?.toDateString() ?? '');
    formData.append("WeightVehicleEmpty", vehicle.weightVehicleEmpty.toString() ?? '0');
    formData.append("StateVehicle", vehicle.stateVehicle ?? '');
    if(vehicle?.companyId){
      formData.append("CompanyId", vehicle?.companyId?.toString());
    }
    // formData.append("VehicleBrandsCode", vehicle.vehicleBrandsCode ?? '');
    // formData.append("VehicleConfigurationCode", vehicle.vehicleConfigurationCode ?? '');
    // formData.append("UnitMeasureCode", vehicle.unitMeasureCode ?? '');
    // formData.append("CodTipoIdPropietario", vehicle.codTipoIdPropietario ?? '');
    // formData.append("NumIdPropietario", vehicle.numIdPropietario ?? '');
    // formData.append("CodTipoIdTenedor", vehicle.codTipoIdTenedor ?? '');
    // formData.append("NumIdTenedor", vehicle.numIdTenedor ?? '');
    // formData.append("TypeIdVehicle", vehicle.toString());
    formData.append("VehicleBrandsCode", vehicle?.vehicleBrandsCode ?? '');
    formData.append("VehicleBrandsName", vehicle?.vehicleBrandsName ?? '');
    formData.append("VehicleConfigurationCode", vehicle?.vehicleConfigurationCode ?? '');
    formData.append("VehicleConfigurationName", vehicle?.vehicleConfigurationName ?? '');
    formData.append("UnitMeasureCode", vehicle?.unitMeasureCode ?? '');
    formData.append("CodTipoIdPropietario", vehicle?.codTipoIdPropietario ?? '');
    formData.append("NumIdPropietario", vehicle?.numIdPropietario ?? '');
    formData.append("CodTipoIdTenedor", vehicle?.codTipoIdTenedor ?? '');
    formData.append("NumIdTenedor", vehicle?.numIdTenedor ?? '');
    formData.append("TypeIdVehicle", vehicle?.typeIdVehicle ?? '');
    formData.append("TypeNameVehicle", vehicle?.typeNameVehicle ?? '');
    formData.append("UnitMeasureName", vehicle?.unitMeasureName ?? '');
    formData.append("namePropietario", vehicle?.namePropietario ?? '');
    formData.append("nameTenedor", vehicle?.nameTenedor ?? '');
    formData.append("tipo", vehicle?.tipo ?? '');
    formData.append("codLineFreightVehicle", vehicle?.codLineFreightVehicle?.toString() ?? '0');
    formData.append("nameLineFreightVehicle", vehicle?.nameLineFreightVehicle ?? '');
    formData.append("numAxes", vehicle?.numAxes?.toString() ?? '0');
    formData.append("colorCodeVehicleLoad", vehicle?.colorCodeVehicleLoad?.toString() ?? '0');
    formData.append("colorNameVehicleLoad", vehicle?.colorNameVehicleLoad ?? '');
    formData.append("capacityUnitLoad", vehicle?.capacityUnitLoad?.toString() ?? '0');
    formData.append("codTypeBodywork", vehicle?.codTypeBodywork?.toString() ?? '0');
    formData.append("nameTypeBodywork", vehicle?.nameTypeBodywork ?? '');
    formData.append("codFuelType", vehicle?.codFuelType ?? '');
    formData.append("nameFuelType", vehicle?.nameFuelType ?? '');
    formData.append("soatInsurancenumber", vehicle?.soatInsurancenumber ?? '');
    formData.append("numNitInsurerSoat", vehicle?.numNitInsurerSoat?.toString() ?? '0');
    // formData.append("numNitInsurerSoat", vehicle?.numNitInsurerSoat );
    formData.append("nameInsurerSoat", vehicle?.nameInsurerSoat ?? '');
    formData.append("nonMinistry", vehicle?.nonMinistry?.toString()  ?? '');

    const url = `${this.apiEndpoint}/vehicles/upsertingform/${vehicle.driverId}`;

    return this._httpClient.post(url, formData);
  }

  public addVehicle(vehicle: Vehicle): Promise<Object> {
    console.log(vehicle);
    return;
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpoint}/vehicles`;

    return this._httpClient.post(url, vehicle, {
      headers: headers,
    })
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  getVehicle(id: number): Promise<any> {
    let url = `${this.apiEndpoint}/vehicles/driver/${id}`;

    return this._httpClient
      .get(url)
      .toPromise();
  }

  public upload(formData: FormData): Promise<any> {
    let url = `${this.apiEndpoint}/upload`;

    return this._httpClient
      .post(url, formData)
      .toPromise();
  }

  // public saveDriver(user): Promise<any> {
  //   let headers = new HttpHeaders();
  //   headers.append("Content-Type", "application/json");

  //   let url = `${this.apiEndpoint}/users/driver`;

  //   return this._httpClient
  //     .post(url, user, {
  //       headers: headers,
  //     })
  //     .toPromise();
  // }



  public saveDriver(user: any,
    licenseImage: any,
    enrollmentTruckImage: any,
    trailerImage: any,
    soattruckImage: any,
    soattrailerImage: any,
    licenseImageName: string,
    enrollmentTruckImageName: string,
    trailerImageName: string,
    soattruckImageName: string,
    soattrailerImageName: string,
    groupsList:  any[] 
  ) {
    // let headers = new HttpHeaders();
    // headers.append("Content-Type", "application/json");

    // let url = `${this.apiEndpoint}/users/driver`;

    // return this._httpClient
    //   .post(url, user, {
    //     headers: headers,
    //   })
    //   .toPromise();
    const formData = new FormData();
    formData.append("id", user?.id?.toString() ?? '0');
    formData.append("firstName", user?.firstName ?? '');
    formData.append("lastName", user?.lastName ?? '');
    formData.append("secondLastName", user?.secondLastName ?? '');
    formData.append("email", user?.email ?? '');
    formData.append("password", user?.password ?? '');
    formData.append("phone", user?.phone ?? '');
    formData.append("type", user?.type ?? '');
    formData.append("documentTypeId", user?.documentTypeId ?? '');
    formData.append("documentTypeName", user?.documentTypeName ?? '');
    formData.append("documentNumber", user?.documentNumber ?? '');
    formData.append("mobile", user?.mobile ?? '');
    formData.append("address", user?.address ?? '');
    formData.append("birthday", user?.birthday ?? '');
    formData.append("userId", user?.userId ?? '0');
    formData.append("municipioCode", user?.municipioCode ?? '');
    formData.append("municipioName", user?.municipioName ?? '');
    if (licenseImage) formData.append("licenseImage", licenseImage, licenseImageName);
    if (enrollmentTruckImage) formData.append("enrollmentTruckImage", enrollmentTruckImage, enrollmentTruckImageName);
    if (trailerImage) formData.append("trailerImage", trailerImage, trailerImageName);
    if (soattruckImage) formData.append("soattruckImage", soattruckImage, soattruckImageName);
    if (soattrailerImage) formData.append("soattrailerImage", soattrailerImage, soattrailerImageName);
    formData.append("licenseImageUrl", user?.driver?.licenseImage ?? '');
    formData.append("enrollmentTruckImageUrl", user?.driver?.enrollmentTruckImage ?? '');
    formData.append("trailerImageUrl", user?.driver?.trailerImage ?? '');
    formData.append("soattruckImageUrl", user?.driver?.soattruckImage ?? '');
    formData.append("soattrailerImageUrl", user?.driver?.soattrailerImage ?? '');
    formData.append("weightTruck", user?.driver?.weightTruck?.toString() ?? '');
    formData.append("weightTrailer", user?.driver?.weightTrailer?.toString() ?? '');
    formData.append("internalDriver", user?.internalDriver ?? '');
    formData.append("stateDriver", user?.stateDriver ?? '');
    formData.append("codCategoryDrivingLicense", user?.driver?.codCategoryDrivingLicense?.toString() ?? '');
    formData.append("categoryDrivingLicenseName", user?.driver?.categoryDrivingLicenseName?.toString() ?? '');
    formData.append("numDrivingLicense", user?.driver?.numDrivingLicense?.toString() ?? '');
    formData.append("expirationDateLicense", user?.driver.expirationDateLicense ?? '');
    formData.append("insertToMinisterio", user?.driver.insertToMinisterio ?? '');
    formData.append("updateMinisterio", user?.driver.updateMinisterio ?? '');
    formData.append("companyId", user?.companyId ?? '');
    formData.append("owner", user?.owner ?? '');
    formData.append("groupId", user?.groupId ?? '');
    groupsList.map((x,i)=>{
      formData.append(`genericGroups[${i}].idGroup`, x?.id ?? '');

    });

    console.log("userservice",user)

    const url = `${this.apiEndpoint}/users/driver/create-form`;

    return this._httpClient.post(url, formData);


  }

  public getMunicipio(query: string) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpointRndc}/1.0/Maestros/DiviPolAdmin?filter=${query}&PageSize=1000`;

    return this._httpClient
      .get(url, {
        headers: headers,
      });
  }


  public getMunicipioByName(query: string) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpointRndc}/1.0/Maestros/DiviPolAdmin?filter=${query}&PageSize=1000`;

    return this._httpClient
      .get(url, {
        headers: headers,
      });
  }

  public getTiposIdentificacion() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpointRndc}/1.0/Maestros/TiposIdentificacion`;

    return this._httpClient
      .get(url, {
        headers: headers,
      });
  }


  public getCategoriasLicencia() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let url = `${this.apiEndpointRndc}/1.0/Maestros/CategoriasLicencia`;

    return this._httpClient
      .get(url, {
        headers: headers,
      });
  }

  private addUser(user: User): Promise<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    })

    let url = `${this.apiEndpoint}/users`;

    return this._httpClient
      .post(url, user, {
        headers: headers,
      })
      .toPromise();
  }

  private updateUser(user: User) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    })
    let url = `${this.apiEndpoint}/users/${user.id}`;

    return this._httpClient
      .patch(url, user, {
        headers: headers,
      })
      .toPromise();
  }

  private handleError(error: any) {
    console.error("error", error);
    return Promise.reject(error.message || error);
  }

  getDriversApproved() {
    let url = `${this.apiEndpoint}/users/type/driversapproved`;
    return this._httpClient
      .get(url);
  }

  getDriversPendingApproved() {
    let url = `${this.apiEndpoint}/users/type/driverspendingapproved`;
    return this._httpClient
      .get(url);
  }


  updateDriverStatus(id: number, status: string) {
    let url = `${this.apiEndpoint}/users/changeDriverStatus/${id}/${status}`;
    return this._httpClient.patch(url, {});
  }
  

  driversSupervisorSave(body) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    })
    let url = `${this.apiEndpoint}/users/driverssupervisor`;
    return this._httpClient.post(url, JSON.stringify(body),{
      headers: headers});
  }


  getDriversBySupervisorList(id: number): Promise<any> {
    let url = `${this.apiEndpoint}/users/supervisorByDriver/${id}`;

    return this._httpClient
      .get(url)
      .toPromise();
  }


  deleteSupervisorByDriver(id: number) {
    let url = `${this.apiEndpoint}/users/supervisorByDriverDelete/${id}`;

    return  this._httpClient.delete(url);
  }


  getThirdParties() {
    let url = `${this.apiEndpoint}/users/type/thirdparties`;
    return this._httpClient
      .get(url);
  }


  
  thirdPartiesSave(body) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    })
    let url = `${this.apiEndpoint}/users/thirdparties`;
    return this._httpClient.post(url, JSON.stringify(body),{
      headers: headers});
  }


  thirdPartiesUpdate(body) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    })
    let url = `${this.apiEndpoint}/users/updatethirdparties`;
    return this._httpClient.post(url, JSON.stringify(body),{
      headers: headers});
  }


  
  getDriversAndThirdParties() {
    let url = `${this.apiEndpoint}/users/driversAndThirdParties`;
    return this._httpClient
      .get(url);
  }


  getDriversCompany() {
    let url = `${this.apiEndpoint}/users/type/driversCompany`;

    return this._httpClient
      .get(url);
  }

}
