import {Deserializable} from './deserializable.model';
import { DriverByGroup } from './driverbygroup.models';

export class Driver implements Deserializable {
    public id: number;
    public userId: number;
    public municipioCode: string;
    public municipioName: string;
    public licenseImage: string;
    public enrollmentTruckImage: string;
    public TrailerImage: string;
    public SOATTruckImage: string;
    public SOATTrailerImage: string;
    public weightTruck: number;
    public weightTrailer: number;
    public internalDriver: boolean;
    public stateDriver: string;
    public codCategoryDrivingLicense: string;
    public categoryDrivingLicenseName: string;
    public numDrivingLicense: string;
    public expirationDateLicense: string;
    public insertToMinisterio: boolean;
    public updateMinisterio: boolean;
    public owner: boolean;
    public idGroup: number;
    public groupName: string;
    public driverByGroup: DriverByGroup;



  deserialize(input: any): this {
    return Object.assign(this, input);
  } 

}