import { Component, OnInit } from '@angular/core';
import { TravelService } from '../admin/travel/travel.service';
import { DriverUserModel } from '../models/driver-user.model';
import { Driver } from '../models/driver.model';
import { User } from '../models/user.model';
import { v4 as uuidv4 } from 'uuid';

import { SnackbarService } from 'ngx-snackbar';
import { UserService } from '../admin/user/user.service';
import { Message, MessageService, PrimeNGConfig } from 'primeng/api';
import * as moment from 'moment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DocumentTypeService } from '../admin/documentType/documentType.service';
import { IsLoadingService } from '@service-work/is-loading';

enum MessageSnackbar {
  Error,
  Info,
  Success
}

@Component({
  selector: 'app-driver-public-registration',
  templateUrl: './driver-public-registration.component.html',
  styleUrls: ['./driver-public-registration.component.scss'],
  providers: [TravelService, UserService, SnackbarService, MessageService, DocumentTypeService]
})
export class DriverPublicRegistrationComponent implements OnInit {
  backgroundColor = '';
  municipioSelected = null;
  municipio = null;
  colorSnackbar = '';
  driverUser: User = new User();
  birthday: Date | null = null;
  documentTypeList = [];
  internalDriver=false;

  msgs1: Message[];
  msgs2: Message[];
  text: string;
  results= [];
  licenseImage: any = null;
  TrailerImage: any = null;
  enrollmentTruckImage: any = null;
  SOATTruckImage: any = null;
  SOATTrailerImage: any = null;
  licenseImageName: string = "";
  TrailerImageName: string = "";
  enrollmentTruckImageName: string = "";
  SOATTruckImageName: string = "";
  SOATTrailerImageName: string = "";


  constructor(private _travelService: TravelService,
    private _userService: UserService,
    private snackbarService: SnackbarService,
    private messageService: MessageService, private primengConfig: PrimeNGConfig,
    public _isLoadingService: IsLoadingService,

    private _documentTypeService: DocumentTypeService
  ) {
    this.driverUser.driver = new Driver();
  }

  ngOnInit(): void {
    this.getDocumentTypes();
  }

  search(event) {
    // this.mylookupservice.getResults(event.query).then(data => {
    //     this.results = data;
    // });
    this._userService.getMunicipio(event.query).subscribe(response => {
      this.results = response['data'] ?? [];
    },
      errorResult => {

      }
    );
  }
  onFileLicenciaSelect(event) {
    this.licenseImage = event.target.files[0];
    if ((this.licenseImage?.size * 0.001) >1024) {
      this.messageService.add({ severity: 'error', summary: 'Error leyendo la imagen!', detail: `Falló al leer la imagen de la licencia, el tamaño máximo permitido es de 1MB y su imagen pesa ${Math.round(this.licenseImage?.size*0.0001)}MB` });
      this.licenseImage = null; this.driverUser.driver.licenseImage = '';
      event.target.files=null;
      return;
    }
    this.licenseImageName = this.getGenericName(uuidv4(), "LICENCE_DRIVER");
    // const file = event.target.files[0];
    // const newName = this.getGenericName(uuidv4(), "LICENCE_DRIVER");
    // this._travelService.uploadTravelFile(file, newName).subscribe(data => {
    //   const resultFile = data;
    //   this.driverUser.driver.licenseImage = resultFile['location'];
    // }, error => {
    //   this.messageService.add({ severity: 'error', summary: 'Error subiendo la imagen!', detail: 'Falló al subir la imagen de la licencia' });

    //   // this.showSnackbarMessage(MessageSnackbar.Error, 'Falló al subir la imagen de la licencia')
    // });
  }

  getDocumentTypes() {
    this._documentTypeService
      .getTiposIdentificacion().subscribe(data => {
        this.documentTypeList = data as any[];
        console.log(data);
      }, error => console.log(error));
  }


  onFileImagenTrailerSelect(event) {
    this.TrailerImage = event.target.files[0];
    if (this.TrailerImage?.size *0.001 > 1024) {
      this.messageService.add({ severity: 'error', summary: 'Error leyendo la imagen!', detail: `Falló al leer la imagen del trailer el tamaño máximo permitido es de 1MB y su imagen pesa ${Math.round(this.TrailerImage?.size*0.0001)}MB` });
      this.TrailerImage = null; this.driverUser.driver.TrailerImage = '';
      return;
    }
    this.TrailerImageName = this.getGenericName(uuidv4(), "TRAILER_TRUCK_DRIVER");
    // const file = event.target.files[0];
    // const newName = this.getGenericName(uuidv4(), "TRAILER_TRUCK_DRIVER");
    // this._travelService.uploadTravelFile(file, newName).subscribe(data => {
    //   const resultFile = data;
    //   this.driverUser.driver.TrailerImage = resultFile['location'];
    // }, error => {
    //   this.messageService.add({ severity: 'error', summary: 'Error subiendo la imagen!', detail: 'Falló al subir la imagen del trailer' });
    // });
  }

  onFileTrailerImageSelect(event) {
    this.enrollmentTruckImage = event.target.files[0];
    if (this.enrollmentTruckImage?.size *0.001 > 1024) {
      this.messageService.add({ severity: 'error', summary: 'Error leyendo la imagen!', detail: `Falló al leer la imagen de la matrícula el tamaño máximo permitido es de 1MB y su imagen pesa ${Math.round(this.enrollmentTruckImage?.size*0.0001)}MB` });
      this.enrollmentTruckImage = null; this.driverUser.driver.enrollmentTruckImage = '';
      return;
    }
    this.enrollmentTruckImageName = this.getGenericName(uuidv4(), "ENROLLMENT_TRUCK_DRIVER");
    // const file = event.target.files[0];
    // const newName = this.getGenericName(uuidv4(), "ENROLLMENT_TRUCK_DRIVER");
    // this._travelService.uploadTravelFile(file, newName).subscribe(data => {
    //   const resultFile = data;
    //   this.driverUser.driver.enrollmentTruckImage = resultFile['location'];
    // }, error => {
    //   this.messageService.add({ severity: 'error', summary: 'Error subiendo la imagen!', detail: 'Falló al subir la imagen de la matrícula' });
    // });
  }

  onFileSOATTruckImageSelect(event) {
    this.SOATTruckImage = event.target.files[0];
    if (this.SOATTruckImage?.size *0.001 > 1024) {
      this.messageService.add({ severity: 'error', summary: 'Error leyendo la imagen!', detail: `Falló al leer la imagen del SOAT del camión, el tamaño máximo permitido es de 1MB y su imagen pesa ${Math.round(this.SOATTruckImage?.size*0.0001)}MB` });
      this.SOATTruckImage = null; this.driverUser.driver.SOATTruckImage = '';
      return;
    }
    this.SOATTruckImageName = this.getGenericName(uuidv4(), "SOAT_TRUCK_DRIVER");
    // const newName = this.getGenericName(uuidv4(), "SOAT_TRUCK_DRIVER");

    // const file = event.target.files[0];
    // const newName = this.getGenericName(uuidv4(), "SOAT_TRUCK_DRIVER");
    // this._travelService.uploadTravelFile(file, newName).subscribe(data => {
    //   const resultFile = data;
    //   this.driverUser.driver.SOATTruckImage = resultFile['location'];
    // }, error => {
    //   this.messageService.add({ severity: 'error', summary: 'Error subiendo la imagen!', detail: 'Falló al subir la imagen del SOAT del camión.' });

    // });
  }
  onFileSOATTrailerImageSelect(event) {
    this.SOATTrailerImage = event.target.files[0];
    if (this.SOATTrailerImage?.size *0.001 > 1024) {
      this.messageService.add({ severity: 'error', summary: 'Error leyendo la imagen!', detail: `Falló al leer la imagen del SOAT del trailer, el tamaño máximo permitido es de 1MB y su imagen pesa ${Math.round(this.SOATTrailerImage?.size*0.0001)}MB` });
      this.SOATTrailerImage = null; this.driverUser.driver.SOATTrailerImage = '';
      return;
    }
    this.SOATTrailerImageName = this.getGenericName(uuidv4(), "SOAT_TRAILER_TRUCK_DRIVER");
    // const file = event.target.files[0];
    // const newName = this.getGenericName(uuidv4(), "SOAT_TRAILER_TRUCK_DRIVER");
    // this._travelService.uploadTravelFile(file, newName).subscribe(data => {
    //   const resultFile = data;
    //   this.driverUser.driver.SOATTrailerImage = resultFile['location'];
    // }, error => {
    //   this.messageService.add({ severity: 'error', summary: 'Error subiendo la imagen!', detail: 'Falló al subir la imagen del SOAT del trailer del camión.' });
    // });
  }

  getExtension(name: string) {
    return /[^.]+$/.exec(name);
  }

  getGenericName(id: string, operation: string) {
    const d = new Date();
    const curr_date = d.getDate();
    const curr_month = d.getMonth() + 1;
    const curr_year = d.getFullYear();
    const curr_hour = d.getHours();
    const curr_min = d.getMinutes();
    const curr_sec = d.getSeconds();
    return `${operation}-${id}-${curr_year}-${curr_month}-${curr_date}-${curr_hour}-${curr_min}-${curr_sec}`
  }

  showSnackbarMessage(messageType: MessageSnackbar, message: string) {
    const bodySnackbar = {
      msg: '',
      action: {
        text: null
      }
    };
    switch (messageType) {
      case MessageSnackbar.Success: {
        this.backgroundColor = "#55c19d";
        bodySnackbar.msg = `<strong>Bien!</strong> ${message}.`;
        this.colorSnackbar = "#fff";
        break;
      }
      case MessageSnackbar.Error: {
        this.backgroundColor = '#dc3545';
        bodySnackbar.msg = `<strong>Error!</strong> ${message}.`;
        this.colorSnackbar = '#fff';
        break;
      }
      default: {
        break;
      }
    }
    this.snackbarService.add(bodySnackbar);
  }

  onSubmit(f: any) {
    this._isLoadingService.add({ key: ["default", "single"] });
    this.driverUser.internalDriver = false;
    this.driverUser.stateDriver = "PENDINGAPPROVED";
    this.driverUser.birthday = moment(this.birthday).format("YYYY-MM-DD").toString();
    this.driverUser.municipioCode = this.municipioSelected?.codigoDivision;
    this.driverUser.municipioName = this.municipioSelected?.nombreDivision;

    const resultDocumentType = this.documentTypeList.find(x => x?.code == this.driverUser.documentTypeId);
    if (resultDocumentType) this.driverUser.documentTypeName = resultDocumentType.label;

    this._userService.saveDriver(this.driverUser, this.licenseImage, this.enrollmentTruckImage, this.TrailerImage, this.SOATTruckImage, this.SOATTrailerImage, this.licenseImageName, this.enrollmentTruckImageName, this.TrailerImageName, this.SOATTruckImageName, this.SOATTrailerImageName,[]).subscribe(
      data => {
        this._isLoadingService.remove({ key: ["default", "single"] });

        this.messageService.add({ severity: 'success', summary: 'Bien!', detail: 'Conductor almacenado!' });

      }, error => {
        this._isLoadingService.remove({ key: ["default", "single"] });

        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Ocurrió un error al guardar el conductor o este conductor ya existe!.' });
        console.log(error);
      });
  }
}
