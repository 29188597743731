import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { AdminModule } from "./admin/admin.module";
import { AppRoutingModule } from "./app.routing";
import { DataTablesModule } from "angular-datatables";
import { ScriptLoaderService } from "./script-loader.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { RestorePasswordComponent } from "./restore-password/restore-password.component";
import { NgxPermissionsModule } from "ngx-permissions";
import { AgmCoreModule } from "@agm/core";
import { GooglePlaceDirective } from "./admin/travel/googleplace.directive";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor } from './utils/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './utils/interceptors/error.interceptor';
import { DriverPublicRegistrationComponent } from "./driver-public-registration/driver-public-registration.component";
import {PanelModule} from 'primeng/panel';
import { CalendarModule } from 'primeng/calendar';
import { MessagesModule } from "primeng/messages";
import { ButtonModule } from "primeng/button";
import { MessageModule } from "primeng/message";
import { InputTextModule } from "primeng/inputtext";
import { RippleModule } from "primeng/ripple";
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { es_ES } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { IsLoadingModule } from "@service-work/is-loading";
import {CardModule} from 'primeng/card';
import { MessageEmailComponent } from "./forgot-password/message-email.component";


registerLocaleData(es);


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RestorePasswordComponent,
    DriverPublicRegistrationComponent,
    MessageEmailComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxPermissionsModule.forRoot(),
    HttpModule,
    AppRoutingModule,
    AdminModule,
    ReactiveFormsModule,
    DataTablesModule,
    PanelModule,
    NgSelectModule,
    HttpClientModule,
    CalendarModule,
    NzDatePickerModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyD3L0sSx9B3G8rWu960w5eM3j9XO-ghaxM",
      libraries: ["places"],
    }),
    MessageModule,
    MessagesModule,
    ButtonModule,
    InputTextModule,
    RippleModule,
    AutoCompleteModule,
    IsLoadingModule,
    CardModule
  ],
  providers: [ScriptLoaderService, HttpClientModule,
    // {provide:API_BASE_URL, useValue:EnvironmentVariables.API_URL},
    // {provide:API_BASE_URL_AUTH, useValue:EnvironmentVariables.API_URL},
    // {provide:API_BASE_URL_UPLOAD_FILE, useValue:EnvironmentVariables.API_URL},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NZ_I18N, useValue: es_ES },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
