

import { Company } from './company.models';
import { Deserializable } from './deserializable.model';
import { Driver } from './driver.model';

export class User implements Deserializable {
    public id: number;
    public firstName: string;
    public lastName: string;
    public secondLastName: string;
    public email: string;
    public phone: string;
    public password: string;
    public type: string;
    public documentTypeId: number;
    public documentTypeName: string;
    public documentNumber: string;
    public municipioCode : string;
    public municipioName: string;
    public mobile: string;
    public address: string;
    public birthday:string;
    public driver: Driver;
    public internalDriver: boolean;
    public stateDriver: string;
    public codigoSede: string;
    public nombreSede: string;
    public updateMinisterio: boolean;
    public companyId: number;
    public hasVehicle: boolean;
    public owner: boolean;
    public latitud: number;
    public longitud: number;
    public company: Company;
    public groupId: number;
    public groupName: string;
    public groupListId: string;
    public groupListName: string;



    deserialize(input: any): this {
        return Object.assign(this, input);
    }

}