import { Component, OnInit, HostListener } from '@angular/core';
import { AutoLogoutService } from './auto-logout-service.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AutoLogoutService]
})

export class AppComponent implements OnInit {
  title = 'app';
  permissions$;

  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler($event) {
  $event.returnValue = "Are you sure?";
 }

  constructor(private autoLogoutService: AutoLogoutService, private permissionService: NgxPermissionsService) { 
  }

  ngOnInit() {
    localStorage.setItem('lastAction', Date.now().toString());
    this.loadPermissions();
}

  loadPermissions(){
    var currentUser = localStorage.getItem("currentUser");
    let user = JSON.parse(currentUser);
    if(user != null && user.roles){
      let roles = user.roles;
      let permissions = [];

      for(let role of roles) {
          for(let permission of role.permissions) {
            permissions[permission.id] = permission.name;
          }
      }
      this.permissionService.loadPermissions(permissions);
    }
  }

}
